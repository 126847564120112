import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from './error.service';
import { Subject, takeUntil } from 'rxjs';
import { AppConstants } from '../app.constants';
import { ErrorEnum, ErrorMessageEnum } from './error.enum';
import { MsalAuthService } from '../shared/service/msal-auth/msal-auth.service';
import { PageRoute } from '../shared/enums/page-routes.enum';
import { SessionService } from '../session/session.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  errorMessage!: string;
  showButton = false;
  buttonLabel = '';

  constructor(
    private errorService: ErrorService,
    private sessionService: SessionService,
    private msalAuthService: MsalAuthService
  ) {}

  ngOnInit(): void {
    this.errorService.error$.pipe(takeUntil(this._destroy$)).subscribe((error) => {
      this.showButton = false;
      this.buttonLabel = '';

      if (error.status === ErrorEnum.Unauthorized) {
        this.errorMessage = 'error.login.unauthorized';
        this.buttonLabel = 'label.button.back-to-login.label';
        this.showButton = true;
      } else if (error.status === ErrorEnum.SessionTimeout) {
        this.errorMessage = 'error.message.sessionTimeoutExpiredError';
        this.buttonLabel = 'label.button.refresh.label';
        this.showButton = true;
      } else if (
        error.status === ErrorEnum.AuthenticationInvalid ||
        (error.status === ErrorEnum.InternalServerError && error.error === ErrorMessageEnum.AuthenticationInvalid)
      ) {
        this.errorMessage = 'error.login.authenticationInvalid';
        this.buttonLabel = 'label.button.refresh.label';
        this.showButton = true;
      } else if (error.status === ErrorEnum.InternalServerError && error.error === ErrorMessageEnum.ErrorRetrievingStaffProfile) {
        this.errorMessage = 'error.message.invalidEmployee';
      } else if (error.status === ErrorEnum.InternalServerError && error.error === ErrorMessageEnum.WorkdayDowntime) {
        this.errorMessage = 'error.message.workDayDownTimeError';
      } else {
        this.errorMessage = 'error.message.generic';
      }
    });
  }

  clickButton(): void {
    this.sessionService.clearSessionStorage();
    if (this.msalAuthService.hasSSOLogin) this.msalAuthService.login();
    else window.location.href = PageRoute.Home;
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  get isSsoAuthenticated(): boolean {
    return this.msalAuthService.hasSSOLogin ? this.msalAuthService.authenticated : false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
