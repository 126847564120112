import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil, take, map, distinctUntilChanged } from 'rxjs';
import { FlightSearchService } from './flight-search.service';
import { DropdownMultiSelectConfig } from '../../../shared/components/dropdown-multiselect/dropdown-multiselect.component';
import { BookingFlightTypeEnum, DateFieldTypeEnum, PassengerTypeEnum } from '../../flight/flight.enum';
import { PassengerModalData } from './flight-search-passenger-modal/flight-search-passenger-modal.component';
import { PageRoute } from '../../../shared/enums/page-routes.enum';
import { Router } from '@angular/router';
import { FlightService } from '../../flight/flight.service';
import { FlightConstants, PassengerErrorConstants } from '../../flight/flight.constants';
import { NotificationService } from '../../../shared/components/notification/notification.service';
import { ResourceService } from '../../../shared/service/resources/resources.service';
import {
  PassengerCriteria,
  Passenger,
  FlightLowfareEstimateRequest,
  FlightSearchData
} from '../../flight/flight.model';
import { StaffProfile } from '../../../profile/profile.model';
import { ProfileService } from '../../../profile/profile.service';
import { AppConstants } from '../../../app.constants';
import { NotificationData } from '../../../shared/components/notification/notification.model';
import { BookingService } from '../../booking.service';
import { Booking } from '../../booking.model';
import { SessionService } from '../../../session/session.service';
import { DatePipe } from '@angular/common';
import { Market, StationResponse } from '../../../shared/service/resources/resource.model';
import { environment } from '../../../../environments/environment';
import { HeaderService } from '../../../header/header.service';
import { AccountService } from '../../../account/account.service';
import { UserInfo } from '../../../account/account.model';
import { DropdownConfig, DropdownOption } from '../../../shared/components/dropdown/dropdown.model';

@Component({
  selector: 'app-flight-search',
  templateUrl: './flight-search.component.html',
  styleUrls: ['./flight-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  readonly dateFieldTypes = DateFieldTypeEnum;
  readonly dateInputFormatConstant = AppConstants.DefaultDisplayDateFormat;

  _loadFormForModify = false;

  @Input() isModify = false;
  @Input()
  get loadFormForModify(): boolean {
    return this._loadFormForModify;
  }
  set loadFormForModify(value: boolean) {
    this._loadFormForModify = value;
    if (value) {
      if (this.isChangeFlightFlow) this.initializeChangeFlightForm();
      else this.initializeModifyForm();
    }
  }
  @Output() onClickLetsGo = new EventEmitter<void>();

  staffTravelPortalForm!: FormGroup;
  disableSubmit = false;

  origin!: string;
  destination!: string;

  fromCityConfigTitles!: DropdownConfig;
  originMarket: Market | undefined;
  isOriginModalVisible = false;
  isSelectingOrigin = false;

  toCityConfigTitles!: DropdownConfig;
  destinationMarket: Market | undefined;
  isDestinationModalVisible = false;
  isSelectingDestination = false;
  destinationShowLoader = false;

  passengersConfigTitle!: DropdownMultiSelectConfig;
  passengers: string[] = [];
  passengerModalData!: PassengerModalData;
  showPassengerModal = false;
  passengersShowLoader = false;

  datepicker = {
    modalShow: false,
    show: false,
    selectedField: DateFieldTypeEnum.Return
  };

  allCitySelection: DropdownOption = {
    value: 'All Cities',
    code: '',
    showCodeBanner: false,
    description: '',
    isDisabled: true,
    data: {}
  };

  stations: StationResponse[] = [];

  constructor(
    private resourceService: ResourceService,
    private flightSearchService: FlightSearchService,
    private bookingService: BookingService,
    private profileService: ProfileService,
    private flightService: FlightService,
    private notificationService: NotificationService,
    private accountService: AccountService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private headerService: HeaderService
  ) {
    this.staffTravelPortalForm = this.flightSearchService.flightSearchForm;
    this.fromCityConfigTitles = this.flightSearchService.getFromLocationConfig();
    this.toCityConfigTitles = this.flightSearchService.getToLocationConfig();
    this.passengersConfigTitle = this.flightSearchService.getPassengerConfig();
  }

  ngOnInit(): void {
    this.staffTravelPortalForm.enable();
    this.flightSearchService.setCanManageFirstJourney(true);

    this.configForPassengerDropdown();
    this.configForMarketDropdowns();
    this.observeData();

    if (!this.isChangeFlightFlow) {
      const formData = this.flightSearchService.getFormData();
      if (this.staffTravelPortalForm && !formData) {
        this.flightSearchService.resetForm();
      }

      if (this.isModify) {
        this.getStaffProfileForModify();
      } else {
        this.passengersFieldLoading(true);
        this.profileService
          .retrieveStaffProfile$({ idToken: this.idToken }, true)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              if (!this.passengersConfigTitle.options.length) this.getStaffProfileBeneficiaries(response?.data ?? null);
              this.passengersFieldLoading(false);
            },
            error: () => {
              this.passengersFieldLoading(false);
            }
          });
      }
    }

    if (!this.isModify) {
      this.sessionService.resetCurrencyToDefaultValues();
      this.bookingService.resetBookingInfo();
      this.headerService.setCurrencyCode();
    }

    //get stations
    this.resourceService
      .getStations$()
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: StationResponse[]) => {
        this.stations = response;
      });

    this.updateCalendarFlightLowfareEstimate();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const data = this.flightSearchService.getFormData();
      if (data) {
        this.staffTravelPortalForm.patchValue(data);
        this.staffTravelPortalForm.get('passengers')?.setValue(data.passengers ? data.passengers : []);
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  setIsReturn(isReturn: boolean): void {
    if (!this.isChangeFlightFlow) {
      this.staffTravelPortalForm.get('isReturn')?.setValue(isReturn);
    }
  }

  //#region Modify
  initializeChangeFlightForm(): void {
    this.getStaffProfileForModify();
    this.bookingService.booking$.pipe(take(1)).subscribe((booking) => {
      if (booking) {
        const isReturnTrip = booking.journeys.length > 1;
        const canManageJourney = !booking.journeys[0].canManageJourney;

        this.staffTravelPortalForm.enable();
        this.flightSearchService.setCanManageFirstJourney(booking.journeys[0].canManageJourney);

        if (canManageJourney) {
          this.staffTravelPortalForm.get('departDate')?.disable();
          this.staffTravelPortalForm.get('fromCityAirport')?.disable();
          this.staffTravelPortalForm.get('toCityAirport')?.disable();
        }

        this.updateBookFlightForm(booking, isReturnTrip);
        this.flightService.setChangeFlightData(booking, this.bookingService.isFocBooking);
      }
    });
  }

  initializeModifyForm(): void {
    this.getStaffProfileForModify();
    const data = this.flightSearchService.getFormData();
    if (data) {
      this.staffTravelPortalForm.patchValue(data);
      this.staffTravelPortalForm.get('passengers')?.setValue(data.passengers ? data.passengers : []);
    }
  }

  updateBookFlightForm(booking: Booking, isReturnTrip: boolean): void {
    const departingJourney = booking.journeys[0];
    const returnJourney = isReturnTrip ? booking.journeys[1] : null;

    //departDate & returnDate
    const departDate = this.flightSearchService.formatDate(
      departingJourney.designator.departure,
      AppConstants.DefaultDisplayDateFormat
    );
    const returnDate =
      isReturnTrip && returnJourney
        ? this.flightSearchService.formatDate(returnJourney.designator.arrival, AppConstants.DefaultDisplayDateFormat)
        : null;

    this.staffTravelPortalForm.get('departDate')?.setValue(departDate);
    this.staffTravelPortalForm.get('returnDate')?.setValue(returnDate);
    this.flightSearchService.calendar = {
      departDate: departDate,
      returnDate: returnDate
    };

    //passengers
    const passengers: number[] = [];
    if (booking?.passengers) {
      booking.passengers.forEach((passenger) => {
        const name = `${passenger.name.first} ${passenger.name.last}`;
        const option = this.passengersConfigTitle.options.find((r) => r.data && r.value == name);
        if (option) {
          option.selected = true;
          passengers.push(option.id);
        }
      });
    }

    //set form data
    this.staffTravelPortalForm.get('isReturn')?.setValue(isReturnTrip);
    this.staffTravelPortalForm.get('passengers')?.setValue(passengers);
    this.staffTravelPortalForm.get('currencyCode')?.setValue(booking.breakDown.currencyCode);
    this.sessionService.setSelectedCurrencyCode(
      this.isChangeFlightFlow
        ? this.sessionService.getCollectedCurrencyCode()
        : booking.breakDown.currencyCode ?? AppConstants.DefaultCurrencyCode
    );
    this.staffTravelPortalForm.get('toCityAirport')?.setValue(booking.journeys[0].designator.destination);
    this.staffTravelPortalForm.get('fromCityAirport')?.setValue(booking.journeys[0].designator.origin);

    //disable field
    this.staffTravelPortalForm.controls['passengers'].disable();
  }

  private getStaffProfileForModify() {
    this.passengersFieldLoading(true);
    this.profileService.staffProfile$.pipe(take(1)).subscribe({
      next: (profile) => {
        if (this.passengersConfigTitle && !this.passengersConfigTitle.options?.length)
          this.getStaffProfileBeneficiaries(profile);
        this.passengersFieldLoading(false);
      },
      error: () => {
        this.passengersFieldLoading(false);
      }
    });
  }
  //#endregion

  //#region Flight Lowfare Estimate
  private updateCalendarFlightLowfareEstimate(): void {
    if (environment.features.greyOutDateWithNoFlights) {
      this.staffTravelPortalForm
        .get('fromCityAirport')
        ?.valueChanges.pipe(takeUntil(this._destroy$))
        .subscribe((origin) => {
          if (origin && this.origin != origin) {
            this.origin = origin;
            if (this.destination) {
              this.getFlightLowfareEstimate();
            }
          }
        });

      this.staffTravelPortalForm
        .get('toCityAirport')
        ?.valueChanges.pipe(takeUntil(this._destroy$))
        .subscribe((destination) => {
          if (destination && this.destination != destination) {
            this.destination = destination;
            if (this.origin) {
              this.getFlightLowfareEstimate();
            }
          }
        });
    }
  }

  private getFlightLowfareEstimate(): void {
    if (environment.features.greyOutDateWithNoFlights) {
      let currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');

      const endDate = `${year + 1}-${month}-${day}`;

      let numberOfDays = this.getDaysBetweenDates(currentDate, new Date(endDate));

      while (numberOfDays != 0) {
        let daysForwardDefault = 90;

        if (numberOfDays < daysForwardDefault) {
          daysForwardDefault = numberOfDays;
        }

        numberOfDays -= daysForwardDefault;

        const request: FlightLowfareEstimateRequest = {
          origin: this.origin,
          destination: this.destination,
          startDate: this.datePipe.transform(currentDate, 'yyyy-MM-dd')?.toString() ?? '',
          daysForward: daysForwardDefault,
          currency: this.staffTravelPortalForm.get('currencyCode')?.value
        };

        this.bookingService
          .getFlightLowFareEstimate$(request)
          .pipe(
            take(1),
            map((lowFares) => lowFares.filter((lowFare) => lowFare.noFlights))
          )
          .subscribe((lowFares) => {
            const markDisabledDates: string[] = [];

            lowFares.forEach((lowFare) => {
              const currentDate = new Date(lowFare.departureDate);
              const startDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd')?.toString() ?? '';
              markDisabledDates.push(startDate);
            });

            this.flightSearchService.setMarkDisabledDate$(markDisabledDates);
          });

        currentDate = new Date(currentDate.setDate(currentDate.getDate() + daysForwardDefault + 1));
      }
    }
  }

  private getDaysBetweenDates(startDate: Date, endDate: Date) {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const startTimestamp = startDate.getTime();
    const endTimestamp = endDate.getTime();

    const daysDifference = Math.round(Math.abs((startTimestamp - endTimestamp) / oneDay));

    return daysDifference;
  }
  //#endregion

  //#region Event
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.isMobile) {
      this.datepicker.show = false;
    } else {
      this.onCloseCalendarModal();
      this.showOriginMarketModal(false);
      this.showDestinationMarketModal(false);
      this.onClosePassengerModal();
    }
  }

  @HostListener('document:click', ['$event'])
  onFocusOut(event?: any) {
    if (
      event?.target.ariaLabel != 'Dropdown input for book-flight-search.select-origin-city-airport' &&
      this.isSelectingOrigin
    ) {
      this.isSelectingOrigin = false;
    }
    if (
      event?.target.ariaLabel != 'Dropdown input for book-flight-search.select-destination-city-airport' &&
      this.isSelectingDestination
    ) {
      this.isSelectingDestination = false;
    }
  }
  //#endregion

  //#region Origin
  showOriginMarketModal(show: boolean) {
    if (!this.fromCityConfigTitles.control.disabled) this.isOriginModalVisible = show;
  }

  onSelectOrigin() {
    this.isSelectingOrigin = true;
  }

  private setOriginLocation() {
    if (this.fromCityConfigTitles) {
      this.resetArray(this.fromCityConfigTitles.options);
      const marketOrigins$ = this.resourceService.getMarketOrigin$();
      marketOrigins$.pipe(take(1)).subscribe({
        next: (response) => {
          response.markets
            .filter((market) =>
              this.userInfo?.isCadetPilot ? AppConstants.CadetPilotMarkets.includes(market.stationCode) : true
            )
            .forEach((market) => {
              if (market) {
                this.fromCityConfigTitles?.options.push({
                  value: market.stationName ?? '',
                  code: market.stationCode ?? '',
                  showCodeBanner: true,
                  description: market.countryName ?? '',
                  isDisabled: false,
                  data: market
                } as DropdownOption);
              }
            });

          if (this.toCityConfigTitles) {
            this.fromCityConfigTitles.options = this.sortLocations(this.fromCityConfigTitles.options);

            if (!this.userInfo?.isCadetPilot) {
              this.fromCityConfigTitles.options.splice(1, 0, this.allCitySelection);
            }
          }
        }
      });
    }
  }
  //#endregion

  //#region Destination
  showDestinationMarketModal(show: boolean) {
    if (!this.toCityConfigTitles.control.disabled) this.isDestinationModalVisible = show;
  }

  onSelectDestination() {
    this.isSelectingDestination = true;
  }

  private setDestinationLocation(origin: string, destination: string | null = null) {
    if (this.flightSearchService.originCode !== origin) {
      this.destinationFieldLoading(true);

      this.resetArray(this.toCityConfigTitles.options);
      const marketDestination$ =
        origin == 'ALL' ? this.resourceService.getMarketOrigin$() : this.resourceService.getMarketDestination$(origin);

      marketDestination$.pipe(take(1)).subscribe({
        next: (response) => {
          response.markets
            .filter((market) =>
              this.userInfo?.isCadetPilot ? AppConstants.CadetPilotMarkets.includes(market.stationCode) : true
            )
            .forEach((market) => {
              if (market) {
                this.toCityConfigTitles?.options.push({
                  value: market.stationName ?? '',
                  code: market.stationCode ?? '',
                  showCodeBanner: true,
                  description: market.countryName ?? '',
                  isDisabled: false,
                  data: market
                } as DropdownOption);
              }
            });

          if (this.toCityConfigTitles) {
            this.toCityConfigTitles.options = this.sortLocations(this.toCityConfigTitles.options);

            if (!this.userInfo?.isCadetPilot) {
              this.toCityConfigTitles?.options.splice(
                origin === AppConstants.SingaporeCode ? 0 : 1,
                0,
                this.allCitySelection
              );
            }
          }

          //Validate if station not in list
          if (!this.toCityConfigTitles.options.some((p) => p.code === destination)) {
            this.setControlValue('toCityAirport', null);
          }

          this.flightSearchService.originCode = origin;
          this.destinationFieldLoading(false);
        },
        error: () => {
          this.destinationFieldLoading(false);
        }
      });
    }
  }

  private destinationFieldLoading(show: boolean) {
    this.destinationShowLoader = show;
    this.changeDetector.detectChanges();
  }
  //#endregion

  //#region Passenger
  onShowPassengerModal() {
    if (!this.passengersConfigTitle.control.disabled) {
      this.showPassengerModal = true;
      if (this.isChangeFlightFlow) {
        this.showPassengerModal = false;
      }
    }
  }

  onClosePassengerModal() {
    this.showPassengerModal = false;
  }

  private passengersFieldLoading(show: boolean) {
    this.passengersShowLoader = show;
    this.staffTravelPortalForm.get('passengers')?.updateValueAndValidity();
  }
  //#endregion

  //#region DropDowns Config
  private getStaffProfileBeneficiaries(staff: StaffProfile | null): void {
    this.resetArray(this.passengersConfigTitle.options);
    let id = 1;
    if (staff && !staff.isCadetPilot) {
      const passengerTypeCode = this.profileService.getPassengerType(staff.dateOfBirth, new Date());
      this.passengersConfigTitle.options.push({
        id: id,
        title: staff.title,
        value: `${staff.firstName ?? ''} ${staff.lastName ?? ''}`,
        code: id.toString(),
        description: passengerTypeCode,
        isDisabled: false,
        selected: false,
        hasError: !staff.lastName || !staff.firstName,
        errorMessage: !staff.lastName || !staff.firstName ? PassengerErrorConstants.NameError : '',
        imageUrl: '', // no need to show icon, technically all beneficiaries including staff is eligible for thanks
        data: {
          firstName: staff.firstName,
          lastName: staff.lastName,
          dateOfBirth: staff.dateOfBirth,
          gender: staff.gender,
          thanksEligibility: true, // all employee and beneficiary are all elgible for thanks
          passengerTypeCode: '',
          creditsRemaining: staff.creditsRemaining,
          maxEligibility: staff.maxEligibility
        }
      });
      id++;
    }

    if (staff?.beneficiaries?.length) {
      staff?.beneficiaries.forEach((beneficiary) => {
        const passengerTypeCode = this.profileService.getPassengerType(beneficiary.dateOfBirth, new Date());
        this.passengersConfigTitle.options.push({
          id: id,
          title: beneficiary.title,
          value: `${beneficiary.firstName} ${beneficiary.lastName ?? ''}`,
          code: id.toString(),
          description: passengerTypeCode,
          isDisabled: passengerTypeCode == PassengerTypeEnum.INFANT,
          selected: false,
          hasError: !beneficiary.lastName || !beneficiary.firstName,
          errorMessage: !beneficiary.lastName || !beneficiary.firstName ? PassengerErrorConstants.NameError : '',
          imageUrl: '', // no need to show icon, technically all beneficiaries including staff is eligible for thanks
          data: {
            firstName: beneficiary.firstName,
            lastName: beneficiary.lastName,
            dateOfBirth: beneficiary.dateOfBirth,
            gender: beneficiary.gender,
            thanksEligibility: true, // all employee and beneficiary are all elgible for thanks
            passengerTypeCode: '',
            creditsRemaining: beneficiary.creditsRemaining,
            maxEligibility: beneficiary.maxEligibility,
            relationship: beneficiary.relationship.descriptor,
            beneficiaryId: beneficiary.globalPersonNames.id
          }
        });
        id++;
      });

      if (this.passengersConfigTitle.options.length > 2 && staff.isCadetPilot) {
        this.passengersConfigTitle.options.length = 2;
      }
    }

    this.passengersConfigTitle?.options.sort((a, b) =>
      a.description?.toLowerCase().localeCompare(b.description?.toLowerCase())
    );
  }

  private configForMarketDropdowns(): void {
    if (!this.fromCityConfigTitles) {
      this.fromCityConfigTitles = {
        control: this.staffTravelPortalForm.controls['fromCityAirport'],
        isRounded: false,
        componentIdentifier: 'book-flight-search.select-origin-city-airport',
        options: [],
        hasPrefixIcon: true,
        showArrowDown: false
      };
      this.setOriginLocation();
    }

    if (!this.toCityConfigTitles) {
      this.toCityConfigTitles = {
        control: this.staffTravelPortalForm.controls['toCityAirport'],
        isRounded: false,
        componentIdentifier: 'book-flight-search.select-destination-city-airport',
        options: [],
        hasPrefixIcon: true,
        showArrowDown: false
      };

      this.setDestinationLocation('ALL');
    }
  }

  private configForPassengerDropdown() {
    if (!this.passengersConfigTitle) {
      this.passengersConfigTitle = {
        control: this.staffTravelPortalForm.controls['passengers'],
        isRounded: false,
        componentIdentifier: 'book-flight-search.select-passengers',
        options: [],
        hasPrefixIcon: true,
        showArrowDown: false
      };
    }

    this.passengerModalData = {
      label: '',
      title: FlightConstants.Passengers,
      options: this.passengersConfigTitle.options
    };
  }

  private sortLocations(dropDownOptions: DropdownOption[]) {
    dropDownOptions.sort((a, b) => a.value.toLocaleLowerCase().localeCompare(b.value.toLocaleLowerCase()));

    const singaporeData = dropDownOptions.find((r) => r.code == AppConstants.SingaporeCode);
    const singaporeList = dropDownOptions.filter((r) => r.code == AppConstants.SingaporeCode);
    if (singaporeList) {
      singaporeList.forEach((singapore) => {
        const index = dropDownOptions.findIndex((r) => r.code == singapore.code);
        dropDownOptions.splice(index, 1);
      });
    }

    if (singaporeData) {
      dropDownOptions.unshift(singaporeData);
    }

    return dropDownOptions;
  }

  private observeData(): void {
    this.staffTravelPortalForm
      .get('isReturn')
      ?.valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((value: boolean) => {
        if (!value) {
          this.datepicker.selectedField = DateFieldTypeEnum.Depart;
          this.staffTravelPortalForm.get('returnDate')?.clearValidators();
          this.staffTravelPortalForm.get('returnDate')?.setValue(null);
          this.flightSearchService.calendar.returnDate = null;
          this.staffTravelPortalForm.get('returnDate')?.updateValueAndValidity();
        } else {
          this.datepicker.selectedField = DateFieldTypeEnum.Return;
          this.staffTravelPortalForm.get('returnDate')?.setValidators([Validators.required]);
        }
      });

    this.staffTravelPortalForm.controls['fromCityAirport'].valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((origin) => {
        this.originMarket = undefined;

        if (origin) {
          const location = this.fromCityConfigTitles?.options.find((v) => v.code === origin);
          if (location) {
            this.originMarket = location.data as Market;
          }

          //Validate to load destination
          const destination = this.staffTravelPortalForm.controls['toCityAirport'].value;
          if (origin === destination) {
            this.setControlValue('toCityAirport', null);
            this.setDestinationLocation(origin);
          } else {
            this.setDestinationLocation(origin, destination);
          }
        }
      });

    this.staffTravelPortalForm.controls['toCityAirport'].valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((destination) => {
        this.destinationMarket = undefined;
        if (destination) {
          const location = this.fromCityConfigTitles?.options.find((v) => v.code === destination);
          if (location) {
            this.destinationMarket = location.data as Market;
          }
        }
      });

    this.staffTravelPortalForm.controls['passengers'].valueChanges
      .pipe(takeUntil(this._destroy$), distinctUntilChanged())
      .subscribe((value) => {
        this.resetArray(this.passengers);
        if (value) {
          value.forEach((id: number) => {
            const passenger = this.passengersConfigTitle.options.find((r) => r.id == id);
            if (passenger != null) {
              this.passengers.push(passenger.value);
              passenger.selected = true;
            }
          });
          if (this.flightSearchService.hasPassengerError.code !== '') {
            const passengers: Passenger[] = [];
            this.setPassengers(passengers);
            this.flightService.passengers = passengers;
            this.updatePassengerData();
            this.staffTravelPortalForm.get('passengers')?.updateValueAndValidity();
          }
        }
      });

    this.staffTravelPortalForm.controls['returnDate'].valueChanges
      .pipe(takeUntil(this._destroy$), distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          if (this.flightSearchService.hasPassengerError.code !== '') {
            const passengers: Passenger[] = [];
            this.setPassengers(passengers);
            this.flightService.passengers = passengers;
            this.updatePassengerData();
            this.staffTravelPortalForm.get('passengers')?.updateValueAndValidity();
          }
        }
      });
  }
  //#endregion

  //#region Depart/Return Date
  setDateFieldSelected(type: DateFieldTypeEnum) {
    this.datepicker.selectedField = type;

    if (this.isMobile) this.onShowCalendarModal();
    else this.datepicker.show = true;

    this.scrollDocument();
  }

  onShowCalendarModal(): void {
    this.datepicker.modalShow = true;
  }

  onCloseCalendarModal(): void {
    this.datepicker.modalShow = false;
  }

  onCloseCalendar() {
    this.datepicker.show = false;
  }

  onResizeCalendarView() {
    if (this.isMobile) {
      this.datepicker.show = false;
    } else {
      this.onCloseCalendarModal();
    }
  }
  //#endregion

  //#region Submit (Lets Go button)
  onSubmit(): void {
    this.validateLastDateOfService();

    this.staffTravelPortalForm.updateValueAndValidity();
    this.staffTravelPortalForm.markAllAsTouched();
    if (this.staffTravelPortalForm.invalid) {
      return;
    }

    //validate unaccompanied minor
    const passengers: Passenger[] = [];
    this.setPassengers(passengers);
    if (this.validatePassengersForUnaccompaniedMinor(passengers)) {
      return;
    }

    //set data
    this.flightService.passengers = passengers;
    this.updatePassengerData();

    this.flightService.passengers = passengers;
    this.flightService.setSelectedDepartDate(this._departDate?.value ? new Date(this._departDate.value) : null);
    this.flightService.setSelectedReturnDate(this._returnDate?.value ? new Date(this._returnDate.value) : null);
    this.flightService.setSelectedOrigin(this.originMarket);
    this.flightService.setSelectedDestination(this.destinationMarket);
    this.flightService.setSelectedPassengers(this._passengers?.value ?? []);

    this.setCurrency();
    this.setFormDataAndConfig();
    this.setSelectedForChangeFlight();

    //proceed
    if (this.isModify && !this.isChangeFlightFlow) {
      this.onClickLetsGo.emit();
    } else {
      this.router.navigateByUrl(PageRoute.BookFlightSelection);
    }
  }

  setFormDataAndConfig(): void {
    this.flightSearchService.setFromLocationConfig(this.fromCityConfigTitles);
    this.flightSearchService.setToLocationConfig(this.toCityConfigTitles);
    this.flightSearchService.setPassengerConfig(this.passengersConfigTitle);
    this.flightSearchService.setFormData(this.staffTravelPortalForm.getRawValue() as FlightSearchData);
    this.flightSearchService.originCode = this.fromCityConfigTitles.control.value;
  }

  validateLastDateOfService() {
    if (this.userInfo?.terminationDate) {
      const terminationDate = new Date(this.userInfo.terminationDate);
      if (this._isReturn && this._returnDate?.value) {
        const returnDate = new Date(this._returnDate.value);
        this.staffTravelPortalForm.controls['returnDate'].setErrors(
          returnDate >= terminationDate ? { error: 'Invalid date.' } : null
        );
      }

      if (this._departDate?.value) {
        const departDate = new Date(this._departDate.value);
        this.staffTravelPortalForm.controls['departDate'].setErrors(
          departDate > terminationDate ? { error: 'Invalid date.' } : null
        );
      }
    }
  }

  validatePassengersForUnaccompaniedMinor(passengers: Passenger[]): boolean {
    const departDateValue = this._departDate?.value;
    if (!departDateValue) return false;

    const arrayPassengerHasDateOfBirth: (Date | null)[] = [];
    let isUnaccompaniedMinor = false;
    let havePassengerIsOver18YearsOld = false;
    let allPassengersHaveDateOfBirth = false;

    if (passengers?.length) {
      passengers.forEach((passenger: Passenger) => {
        if (passenger) {
          const dateOfBirth = passenger.dateOfBirth ? new Date(passenger.dateOfBirth) : null;
          if (dateOfBirth) arrayPassengerHasDateOfBirth.push(dateOfBirth);
        }
      });

      if (departDateValue) {
        const departDate = new Date(departDateValue);
        const eighteenYearsBeforDeparture = new Date(new Date(departDate).setFullYear(departDate.getFullYear() - 18));

        havePassengerIsOver18YearsOld = arrayPassengerHasDateOfBirth.some(
          (passengerDateOfBirth) => passengerDateOfBirth && passengerDateOfBirth <= eighteenYearsBeforDeparture
        );
        allPassengersHaveDateOfBirth = arrayPassengerHasDateOfBirth.every((passenger) => passenger);
        isUnaccompaniedMinor = !havePassengerIsOver18YearsOld && allPassengersHaveDateOfBirth;
      }
    }

    if (isUnaccompaniedMinor) {
      this.notificationService.show({
        title: 'notification.title.notification',
        text: 'notification.text.unaccompaniedMinor',
        showCancelButton: true,
        showConfirmButton: false
      } as NotificationData);
    }

    return isUnaccompaniedMinor;
  }

  private updatePassengerData(): void {
    this.flightSearchService.hasPassengerError.code = '';
    const departDate = new Date(this._departDate?.value);
    this.flightService.passengerCriteria = {
      adult: 0,
      child: 0,
      infant: 0
    } as PassengerCriteria;

    if (this.flightService.passengers?.length && departDate) {
      this.flightService.passengers.some((passenger) => {
        const passengerTypeCodeForDepart = this.profileService.getPassengerType(
          passenger?.dateOfBirth ?? new Date().toString(),
          departDate
        );
        if (!this._isReturn) {
          passenger.passengerTypeCode = passengerTypeCodeForDepart;
        } else {
          const returnDate = new Date(this._returnDate?.value);
          const passengerTypeCodeForReturn = this.profileService.getPassengerType(
            passenger?.dateOfBirth ?? new Date().toString(),
            returnDate
          );

          if (passengerTypeCodeForDepart === passengerTypeCodeForReturn) {
            passenger.passengerTypeCode = passengerTypeCodeForDepart;
          } else {
            passenger.passengerTypeCode = '';
            if (
              passengerTypeCodeForDepart === PassengerTypeEnum.INFANT &&
              passengerTypeCodeForReturn === PassengerTypeEnum.CHILD
            ) {
              this.flightSearchService.hasPassengerError.code = PassengerTypeEnum.INFANT + PassengerTypeEnum.CHILD;
            } else if (
              passengerTypeCodeForDepart === PassengerTypeEnum.CHILD &&
              passengerTypeCodeForReturn === PassengerTypeEnum.ADULT
            ) {
              this.flightSearchService.hasPassengerError.code = PassengerTypeEnum.CHILD + PassengerTypeEnum.ADULT;
            }

            return true;
          }
        }
        switch (passenger.passengerTypeCode) {
          case PassengerTypeEnum.ADULT:
            this.flightService.passengerCriteria.adult++;
            break;
          case PassengerTypeEnum.CHILD:
            this.flightService.passengerCriteria.child++;
            break;
          default:
            this.flightService.passengerCriteria.infant++;
            break;
        }
        return false;
      });
    }
  }

  private setPassengers(passengers: Passenger[]): void {
    if (this._passengers?.value?.length) {
      this._passengers?.value.forEach((id: number) => {
        const passengerConfig = this.passengersConfigTitle?.options.find((v) => v.id == id);
        if (passengerConfig) {
          const passenger = passengerConfig.data as Passenger;
          passenger.title = passengerConfig.title;
          passengers.push(passenger);
        }
      });
    }
  }

  private setSelectedForChangeFlight() {
    if (this.flightService.changeFlightData) {
      const departData = this.flightService.changeFlightData.depart;
      const returnData = this.flightService.changeFlightData.return;
      const departDate = new Date(departData.flightData.designator.departure);
      const returnDate = returnData ? new Date(returnData?.flightData.designator.arrival) : null;
      const formDepartDate = new Date(this._departDate?.value);
      const formReturnDte = new Date(this._returnDate?.value);

      if (
        departData.flightData.designator.origin === this._origin?.value &&
        departData.flightData.designator.destination === this._destination?.value &&
        formDepartDate.getDate() === departDate.getDate() &&
        formDepartDate.getFullYear() === departDate.getFullYear() &&
        formDepartDate.getMonth() === departDate.getMonth()
      ) {
        this.flightService.setSelectedDepartingFlightData(departData.flightData);
      }

      if (
        returnData &&
        returnDate &&
        returnData.flightData.designator.destination === this._origin?.value &&
        returnData.flightData.designator.origin === this._destination?.value &&
        formReturnDte.getDate() === returnDate.getDate() &&
        formReturnDte.getFullYear() === returnDate.getFullYear() &&
        formReturnDte.getMonth() === returnDate.getMonth()
      ) {
        this.flightService.setSelectedReturningFlightData(returnData.flightData);
      }
    }
  }

  private setCurrency() {
    const station = this.stations.find(
      (v) => v.countryCode === this.originMarket?.countryCode && v.code === this.originMarket?.stationCode
    );
    const stationCurrency = station?.currencyCode ?? AppConstants.DefaultCurrencyCode;
    const selectedCurrency = this.isChangeFlightFlow
      ? this.sessionService.getCollectedCurrencyCode()
      : stationCurrency ?? AppConstants.DefaultCurrencyCode;

    this.staffTravelPortalForm.get('currencyCode')?.setValue(stationCurrency);
    this.sessionService.setSelectedCurrencyCode(selectedCurrency);
    this.flightService.setSelectedCurrency(selectedCurrency);
    this.sessionService.setCurrencyCodeToSessionStorage(selectedCurrency);
  }

  private resetArray(array: any) {
    array.splice(0, array.length);
  }

  //#endregion

  //#region Form Get
  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  get _isReturn(): boolean {
    return this.staffTravelPortalForm.get('isReturn')?.value;
  }

  get _origin(): AbstractControl | null {
    return this.staffTravelPortalForm.get('fromCityAirport');
  }

  get _destination(): AbstractControl | null {
    return this.staffTravelPortalForm.get('toCityAirport');
  }

  get _departDate(): AbstractControl | null {
    return this.staffTravelPortalForm.get('departDate');
  }

  get _returnDate(): AbstractControl | null {
    return this.staffTravelPortalForm.get('returnDate');
  }

  get _passengers(): AbstractControl | null {
    return this.staffTravelPortalForm.get('passengers');
  }

  get _currencyCode(): string {
    return this.staffTravelPortalForm.get('currencyCode')?.value ?? AppConstants.DefaultCurrencyCode;
  }

  get isChangeFlightFlow(): boolean {
    return this.bookingService.bookingType === BookingFlightTypeEnum.ChangeFlight;
  }

  get canManageDepartFlight(): boolean {
    return this.flightSearchService.getCanManageFirstJourney() ?? true;
  }

  get userInfo(): UserInfo | null {
    return this.accountService.getUserInfo();
  }

  get idToken(): string {
    return this.sessionService.getCurrentUserIdTokenFromLocalStorage();
  }

  //#endregion

  setControlValue(field: string, value: any): void {
    this.staffTravelPortalForm.controls[field].setValue(value);
    this.staffTravelPortalForm.controls[field].updateValueAndValidity();
  }

  scrollDocument(): void {
    if (!this.isModify) {
      setTimeout(() => {
        const scrollingElement = document.scrollingElement || document.body;
        scrollingElement.scrollTo(0, document.body.scrollHeight);
      });
    }
  }

  ngOnDestroy(): void {
    this.setFormDataAndConfig();
    this._destroy$.next();
    this._destroy$.complete();
  }
}
